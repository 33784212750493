<template>
    <div id="rodape" class="row no-gutters w-100">
        <div id="video" class="col-12 text-center">
            <b-img-lazy
                alt="Arezzo"
                class="logo"
                fluid
                :src=" require('../../assets/logos/arezzoco-branco.png') "
            ></b-img-lazy>

            <div class="clear"></div>

            <a href="https://www.facebook.com/zzvendas" target="_blank">
                <b-img-lazy
                    alt="Facebook Messager"
                    class="redes"
                    width="16"
                    hright="16"
                    fluid
                    :src=" require('../../assets/img/logo-messager.png') "
                ></b-img-lazy>
            </a>
            <a href="https://www.instagram.com/zzvendas/" target="_blank">
                <b-img-lazy
                    alt="Facebook Instagram"
                    class="redes"
                    width="17"
                    hright="17"
                    fluid
                    :src=" require('../../assets/img/logo-instagram.png') "
                ></b-img-lazy>
            </a>
            <a href="https://www.youtube.com/channel/UC623p_xKyZeaPVm0p30tb8g" target="_blank">
                <b-img-lazy
                    alt="Youtube"
                    class="redes"
                    width="21"
                    hright="21"
                    fluid
                    :src=" require('../../assets/img/logo-youtube.png') "
                ></b-img-lazy>
            </a>

            <div class="clear"></div>

            <div class="links">
                <router-link class="" :to="`/cadastro`">Criar Vitrine Gratuitamente</router-link>
                <router-link class="" :to="`/duvidas-frequentes`" @click.native="scrollMeTo('institucional')">Dúvidas Frequentes</router-link>
                <router-link class="" :to="`/`" @click.native="scrollMeTo('video')">Como Funciona</router-link>
            </div>

            <div class="marcas">
                <b-img-lazy
                    alt="Anacapri"
                    width="64"
                    hright="19"
                    fluid
                    :src=" require('../../assets/logos/anacapri-branco.png') "
                ></b-img-lazy>

                &bull;

                <b-img-lazy
                    alt="Arezzo"
                    width="71"
                    hright="16"
                    fluid
                    :src=" require('../../assets/logos/arezzo.png') "
                ></b-img-lazy>
            </div>

            <p class="copyright">Todos os direitos reservados Arezzo&Co</p>
        </div>
    </div>
</template>

<script>
// import Vue from "vue";
// import store from "@/store";

export default {
    name: 'footerbar',
    props: [],
    mixins: [],
    methods: {
        scrollMeTo(refName) {
            window.scrollTo(0, document.getElementById(refName).offsetTop);
        }
    },
    data() {
        return {}
    },
    watch: {},
}
</script>
