<template>
    <div>
        <div id="header" class="row no-gutters w-100">
            <div class="col-6 col-sm-6 col-md-3">
                <router-link class="" :to="`/`">
                    <h1>
                        <b-img-lazy
                            alt="ZZ Vendas"
                            class="logo" fluid
                            :src=" require('../../assets/logos/zzvendas.png') "
                        ></b-img-lazy>
                        <span>Vendas</span></h1>
                </router-link>
            </div>
            <div class="d-none d-sm-none d-md-block col-sm-9 col-md-9 text-right links align-self-center">
                <router-link class="" :to="`/`" @click.native="scrollMeTo('video')">Como Funciona</router-link>
                <router-link class="" :to="`/duvidas-frequentes`">Dúvidas Frequentes</router-link>
                <router-link class="" :to="`/revendedoras`">Revendedoras</router-link>
                <router-link class="cadastro" :to="`/cadastro`">CADASTRE-SE</router-link>
            </div>
            <div class="d-block d-sm-block d-md-none col-6 col-sm-6 col-md-8 text-right links align-self-center">
                <b-navbar toggleable="md">
                    <router-link class="cadastro" :to="`/cadastro`">CADASTRE-SE</router-link>
                    <b-navbar-toggle target="nav_collapse" v-on:click="showBlackout = !showBlackout"></b-navbar-toggle>
                </b-navbar>
            </div>
        </div>
        <b-collapse is-nav id="nav_collapse" v-model="showBlackout">
            <b-navbar-nav class="ml-auto">
                <router-link class="" :to="`/`" @click.native="scrollMeTo('video')">Como Funciona</router-link>
                <router-link class="" :to="`/duvidas-frequentes`">Dúvidas Frequentes</router-link>
                <router-link class="" :to="`/revendedoras`">Revendedoras</router-link>
            </b-navbar-nav>
        </b-collapse>
        <div class="blackout" v-show="showBlackout"></div>
    </div>
</template>

<script>

export default {
    name: 'headerbar',
    props: [],
    mixins: [],
    methods: {
        scrollMeTo(refName) {
            this.showBlackout = false
            window.scrollTo(0, document.getElementById(refName).offsetTop)
        },
    },
    data() {
        return {
            showBlackout: false,
        }
    },
}
</script>
