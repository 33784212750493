<template>
    <div id="institucional" class="h-100">
        <Headerbar/>
        <div id="corpo" class="row no-gutters w-100">
            <div id="banner" class="col-12">
                <div class="container">
                    <div class="row no-gutters w-100">
                        <div class="col-12 col-md-5 col-lg-4">
                            <p class="azul">
                                <span class="tamanho1">VENHA PARA</span><br/>
                                <span class="tamanho2">a empresa de</span> <span class="tamanho3">Moda</span><br/>
                                <span class="tamanho4">MAIS</span> <span class="tamanho5">DIGITAL</span><br/>
                                <span class="tamanho6">DO BRASIL</span>
                            </p>

                            <p class="preto">
                                Seja uma vendedora digital<br/>
                                e decole sua carreira
                            </p>
                        </div>

                        <div class="col-12 col-md-2 col-lg-4 align-self-center">
                            <router-link class="cadastro" :to="`/cadastro`">CADASTRE-SE</router-link>
                        </div>

                        <div class="col-0 col-md-5 col-lg-4 align-self-center">
                        </div>
                    </div>
                </div>
            </div>

            <div id="baixar" class="col-12 text-center">
                <p>Baixe nosso app!</p>

                <a href="https://apps.apple.com/br/app/zz-vendas/id1515150526" target="_blank">
                    <b-img-lazy
                        alt="Apple Store"
                        fluid
                        :src=" require('../../assets/img/apple-store.png') "
                    ></b-img-lazy>
                </a>

                <a href="https://play.google.com/store/apps/details?id=br.com.hub2154.zzvendas&hl=pt_BR" target="_blank">
                    <b-img-lazy
                        alt="Play Store"
                        fluid
                        :src=" require('../../assets/img/play-store.png') "
                    ></b-img-lazy>
                </a>
            </div>

            <div id="carousel" class="col-12">
                <div class="container">
                    <div id="banners" class="banners" v-if="banners.length > 0">
                        <b-carousel :interval="4000" indicators>
                            <b-carousel-slide
                                v-for="banner in banners" v-bind:key="banner.img"
                                :img-src="banner.img"
                            >
                                <p v-html="banner.txt"></p>
                            </b-carousel-slide>
                        </b-carousel>
                    </div>
                </div>
            </div>

            <div id="video" ref="video" class="col-12 text-center">
                <iframe height="309" src="https://www.youtube.com/embed/2ntjt6I2YkQ?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div class="clear"></div>
                <router-link class="" :to="`/cadastro`">CADASTRE-SE</router-link>
            </div>
        </div>

        <Footerbar/>

    </div>
</template>
<style lang="scss">
#institucional
{
    .carousel-caption
    {
        p
        {
            font-size: 14px;
        }
    }
}

@media (max-width: 767px)
{
    #institucional
    {
        #banners
        {
            width: 400px;

            .carousel-caption
            {
                padding-bottom: 0;
            }
        }
    }
}

@media (min-width: 768px)
{
    #institucional
    {
        #banners
        {
            width: 550px;
        }
    }
}
</style>
<script>

import Headerbar from "../../components/site_institucional/headerbar";
import Footerbar from "../../components/site_institucional/footerbar";
import '../../assets/css/institucional.scss';

export default {
    name: 'institucional',
    components: {Headerbar, Footerbar},
    data() {
        return {
            showBlackout: false,
            banners: [
                {txt: "Crie sua vitrine personalizada e exiba sua seleção", img: require('../../assets/banner/slide1-p.png')},
                {txt: "Divulgue suas vitrines e seja comissionada pelas vendas", img: require('../../assets/banner/slide2-p.png')},
                {txt: "Sem investimento algum!", img: require('../../assets/banner/slide3-p.png')}
            ],
            images: [
                require('../../assets/img/fundo-cadastro-full.png'),
                require('../../assets/img/fundo-cadastro-m.png')
            ]
        };
    },
    created() {
        document.title = 'ZZVendas'
    },
}
</script>
